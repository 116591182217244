.PasswordProtectedPage {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.PasswordProtectedPage__inner {
    padding: 1rem;
    border-radius: 3px;
    background: #ededed;
}
.PasswordProtectedPage__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.PasswordProtectedPage__input {
    padding: 0.6rem;
    border: 2px solid;
    border-color: rgba(128, 128, 128, 0.329);
}
.textError {
    color: var(--wb-signal-red);
}
